import * as React from "react";
import Layout from "../components/common/Layout";
import Nav from "../components/common/Nav";
import { graphql, Link } from "gatsby";
import styled from "styled-components";
import {
  Body,
  Section,
  Text,
  TextH2,
} from "../components/common/common-styles";
import { StaticImage } from "gatsby-plugin-image";
import SectionHeader from "../components/common/SectionHeader";
import ContactForm from "../components/common/ContactForm";

const StyledList = styled.ol`
  padding-bottom: 2.5rem;
  padding-top: 40px;
  list-style: none;
  max-width: 750px;
  margin: auto;
  font-family: "Lato";

  p {
    margin-bottom: 0;
  }

  header {
    margin-bottom: 10px;
  }

  .post-list-header h2 {
    color: #00aeef;
    font-family: "Lato";
    text-decoration: none;
  }
  @media (max-width: 800px) {
    padding-left: 30px;
    padding-right: 30px;
  }
`;

const BenefitList = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-evenly;

  padding-left: 20px;
  padding-right: 20px;
  @media (max-width: 900px) {
    flex-direction: column;
    align-items: center;
  }
`;

const SubheaderText = styled.div`
  font-family: "Lato Light";
  font-size: 20px;
  color: #6c6c6c;
  text-align: center;
  max-width: 1300px;
  margin-left: auto;
  margin-right: auto;
  padding-left: 20px;
  padding-right: 20px;
`;

const BenefitTitle = styled.h2`
  font-family: "Palestina";
  font-size: 32px;
  color: #00aeef;
  font-weight: normal;
`;

const BenefitStyle = styled.h2`
  max-width: 650px;

  .benefit-description {
    font-family: "Lato Light";
    font-size: 20px;

    font-weight: normal;
    list-style: none;
  }
`;

function Benefit({
  title,
  descriptions,
}: {
  title: string;
  descriptions: string[];
}) {
  return (
    <BenefitStyle>
      <BenefitTitle>{title.toUpperCase()}</BenefitTitle>
      <ul className="benefit-description">
        {descriptions.map((description) => (
          <li>{description}</li>
        ))}
      </ul>
    </BenefitStyle>
  );
}

// markup
const JobPage = ({ data }: { data: any }) => {
  const posts = data.allMarkdownRemark.nodes;

  return (
    <Layout pageTitle="Jobs">
      <SectionHeader text={"JOIN THE TEAM"} />
      <Section>
        <TextH2>Why Work At Waypoint</TextH2>
        <SubheaderText>
          Our committment to producing happy team members means going above and
          beyond what you’ll typically find in similarly sized companies.
          Keeping a happy, consistent team means producing high-quality,
          consistent projects, in turn, creating happy, consistent clients.
        </SubheaderText>
        <Body>
          <BenefitList>
            <div className="benefit-items">
              <Benefit
                title={"Paid Time Off"}
                descriptions={[
                  "Work/Life balance is core to our business, and our PTO policy reflects that.",
                ]}
              />
              <Benefit
                title={"100% Free Healthcare"}
                descriptions={[
                  "How do we do it? We cover the premiums, as well as contribute to your HSA to cover the deductible.",
                  "Includes health, dental, life, short/long term disablity, and AD&D",
                ]}
              />
              <Benefit
                title={"Paid Bench"}
                descriptions={[
                  "You are a full time employee of Waypoint so you get paid even when you're between clients",
                ]}
              />
              <Benefit
                title={"Blog Posts"}
                descriptions={[
                  "Earn $200 for every blog post you write for the Waypoint site",
                ]}
              />
              <Benefit
                title={"401k"}
                descriptions={["3% to your retirement every paycheck"]}
              />
            </div>
            <div className="benefit-items">
              <Benefit
                title={"Profit Sharing"}
                descriptions={[
                  "Consultants do the work and they deserve to reap the rewards. That's why every quarter we pay out profit sharing to our employees.",
                ]}
              />
              <Benefit
                title={"Overtime"}
                descriptions={[
                  "If you choose to work overtime, you get paid for it.",
                ]}
              />
              <Benefit
                title={"Home Office Budget"}
                descriptions={[
                  "Remote work is more common than ever, so consultants get a $500 budget on hire for any home office related expenses.",
                ]}
              />
              <Benefit
                title={"Laptop"}
                descriptions={[
                  "Every consultant will be provided a development-grade laptop",
                ]}
              />
              <Benefit
                title={"Training Budget"}
                descriptions={[
                  "A reimbursement budget for courses, certs, etc.",
                ]}
              />
            </div>
          </BenefitList>
        </Body>
      </Section>
      <SectionHeader text={"OPEN POSITIONS"} />
      <StyledList>
        {posts.map((post: any) => {
          const title = post.frontmatter.title || post.fields.slug;

          return (
            <li key={post.fields.slug}>
              <article
                className="post-list-item"
                itemScope
                itemType="http://schema.org/Article"
              >
                <header className="post-list-header">
                  <h2>
                    <Link to={post.fields.slug} itemProp="url">
                      <span itemProp="headline">{title}</span>
                    </Link>
                  </h2>
                  <small>{post.frontmatter.date}</small>
                </header>
                <section>
                  <p
                    dangerouslySetInnerHTML={{
                      __html: post.frontmatter.description || post.excerpt,
                    }}
                    itemProp="description"
                  />
                </section>
              </article>
            </li>
          );
        })}
      </StyledList>
      <ContactForm />
    </Layout>
  );
};

export default JobPage;

export const query = graphql`
  query {
    allMarkdownRemark(
      filter: { fileAbsolutePath: { regex: "/(job)/" } }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      nodes {
        excerpt
        fields {
          slug
        }
        frontmatter {
          date(formatString: "MMMM DD, YYYY")
          title
          description
        }
      }
    }
  }
`;
