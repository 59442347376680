import React from "react";
import styled from "styled-components";

const StyledButton = styled.button`
  height: 75px;
  width: 450px;
  font-family: "Palestina", sans-serif;
  font-size: 22px;
  color: white;
  background: ${(props) => (props.color ? props.color : "transparent")};
  border: 3px solid white;
  cursor: pointer;

  @media (max-width: 650px) {
    width: 100%;
  }
`;

const BoxButton = (props: {
  text: string;
  onClick?: () => void;
  color?: string;
  type?: "button" | "submit" | "reset" | undefined;
}) => {
  return (
    <StyledButton color={props.color} type={props.type ? props.type : "button"}>
      {props.text}
    </StyledButton>
  );
};

export default BoxButton;
