import styled from "styled-components";

export const Section = styled.div`
  background-color: rgba(113, 176, 200, 0.05);
  padding-top: 50px;
  padding-bottom: 50px;
`;

export const Body = styled.div`
  margin-left: auto;
  margin-right: auto;
  padding-bottom: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

export const Text = styled.div`
  max-width: 650px;
  font-family: var(--font-heading);
`;

export const ItalicText = styled.div`
  max-width: 650px;
  font-family: var(--font-heading);
  font-style: italic;
`;

export const MobileTitle = styled.h2`
  @media (min-width: 900px) {
    display: none;
  }
`;

export const DesktopTitle = styled.h2`
  @media (max-width: 900px) {
    display: none;
  }
`;

/* new styles from Garret's designs */

export const TextH2 = styled.h2`
  font-family: "Palestina";
  font-size: ${(props) => (props.size ? props.size : "44px")};
  color: ${(props) => (props.color ? props.color : "#00aeef")};
  text-align: center;
  font-weight: normal;
`;

export const StyledBodyText = styled.div`
  font-family: "Lato Light";
  font-size: 20px;
  line-height: 32px;
  text-align: center;
`;
