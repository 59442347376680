import React, { FormEvent, useState } from "react";
import ContactCard from "../../images/contact-card.png";
import Grid from "../../images/grid.png";
import styled from "styled-components";
import BoxButton from "./BoxButton";

const Section = styled.div`
  max-width: 1300px;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;
  margin-bottom: 70px;
  position: relative;
  overflow: hidden;
  padding-left: 50px;

  img {
    @media (max-width: 1050px) {
      display: none;
    }
  }
`;

const FormSection = styled.div`
  display: flex;
  flex-direction: column;
  width: 50%;
  margin-right: 50px;

  @media (max-width: 1050px) {
    width: 80%;
  }

  .contact-form {
    font-size: 15px;
    font-family: "Lato";
    color: #00aeef;

    display: flex;
    flex-direction: column;
    label {
      display: flex;
      flex-direction: column;
      margin-bottom: 27px;
    }
    input {
      border: #00aeef 3px solid;
      height: 35px;
    }
    textarea {
      height: 187px;
      border: #00aeef 3px solid;

      resize: none;
    }
  }
`;

const FormHeader = styled.div`
  font-size: 44px;
  font-family: "Palestina";
  color: #00aeef;
`;

const FormSubhead = styled.div`
  font-size: 18px;
  font-family: "Lato";
  padding-bottom: 20px;
`;
const ContactForm = () => {
  return (
    <Section>
      <FormSection>
        <FormHeader>The next step</FormHeader>
        <FormSubhead>
          Let us know some details about your next project and within one
          business day we’ll follow up with more questions and next steps.
        </FormSubhead>
        <form
          action="/"
          className="contact-form"
          netlify-honeypot="bot-field"
          name="contact"
          method="POST"
          netlify
        >
          <input type="hidden" name="form-name" value="contact" />
          <label>
            NAME
            <input type="text" name="name" />
          </label>
          <label>
            EMAIL
            <input name="email" type="email" />
          </label>
          <label>
            MESSAGE
            <textarea name="message" />
          </label>
          <BoxButton type="submit" text={"SUBMIT"} color={"#00AEEF"} />
        </form>
      </FormSection>
      <img alt={""} src={ContactCard} style={{ width: "50%" }} />
      <img
        src={Grid}
        style={{
          position: "absolute",
          top: "-40%",
          left: "35%",
          width: "100%",
          opacity: 0.3,
        }}
      />
    </Section>
  );
};

export default ContactForm;
