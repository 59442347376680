import HeaderBackground from "../../images/waypoint-pattern-white.png";
import React from "react";
import styled from "styled-components";

const StyledSectionHeader = styled.div`
  height: 218px;
  text-align: center;
  font-family: "Palestina";
  font-size: 106px;
  line-height: 132px;
  color: transparent;
  -webkit-text-stroke: 2px white;
  min-height: 100%;
  overflow: hidden;
  position: relative;
  background: #00aeef;
  width: 100%;
`;

const HeaderText = styled.div`
  margin: 0;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 100%;

  @media (max-width: 900px) {
    font-size: 50px;
  }
`;
function SectionHeader({ text }: { text: string }) {
  return (
    <StyledSectionHeader>
      <img
        style={{
          opacity: 0.3,
          width: "1300px",
          left: 0,
          top: -400,
          position: "absolute",
        }}
        src={HeaderBackground}
        alt={"hi"}
      />
      <HeaderText>{text}</HeaderText>
    </StyledSectionHeader>
  );
}

export default SectionHeader;
